import React, { useState } from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import SecondaryLanding from "../../../components/layouts/secondary-landing";
import HeroChevron from "../../../components/hero/hero-chevron";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import SEO from "../../../components/seo/seo";
import Icon from "../../../components/custom-widgets/icon";
import BestBanksDefault from "../../../components/best-banks/best-banks-default";
import FaqAccordion from "../../../components/faq/faq-accordion";
import VimeoVideoFrame from "../../../components/custom-widgets/vimeo-video-frame";
import StretchedLinkCards from "../../../components/card/stretched-link-cards";
import LoanOfficerSearch from "../../../components/loan-officers/loan-officer-search";

import calcPic from "../../../../static/images/icons/calculator-pictogram.svg";

import getHeroImgVariables from "../../../helpers/getHeroImgVariables";
import incomeData from "../../../data/income-data";

import "./smart-start.bootstrap.scss";
import InfoCircleTooltip from "../../../components/custom-widgets/info-circle-tooltip";

import useCardBlogsData from "../../../hooks/use-card-blogs-data";
import getFeaturedCardBlogData from "../../../helpers/getFeaturedCardBlogData";
import PreApprovalWorkflow from "../../../components/home-loans/pre-approval-workflow";

const SmartStartDownPaymentAssistance = () => {
  const [serviceState, setServiceState] = useState(null);
  const [county, setCounty] = useState(null);
  const [income, setIncome] = useState(null);

  function handleServiceStateChange(event) {
    setServiceState(event.target.value);
    setCounty(null);
  }

  function handleCountyChange(event) {
    setCounty(event.target.value);
  }

  function handleCalculate(event) {
    event.preventDefault();
    const result = incomeData[serviceState].filter((value) => value.county === county)[0].income;
    setIncome(result);
  }

  const pageData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(
        relativePath: { eq: "hero/personal-banking/home-loans/smart-start/hero-smart-start-012523-XXL.jpg" }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: { eq: "hero/personal-banking/home-loans/smart-start/hero-smart-start-012523-XL.jpg" }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: { eq: "hero/personal-banking/home-loans/smart-start/hero-smart-start-012523-LG.jpg" }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: { eq: "hero/personal-banking/home-loans/smart-start/hero-smart-start-012523-MD.jpg" }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: { eq: "hero/personal-banking/home-loans/smart-start/hero-smart-start-012523-SM.jpg" }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: { eq: "hero/personal-banking/home-loans/smart-start/hero-smart-start-012523-XS.jpg" }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: { eq: "hero/personal-banking/home-loans/smart-start/hero-smart-start-012523-XXS.jpg" }
      ) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);

  const heroChevronData = {
    id: "smart-start-hero",
    ...getHeroImgVariables(pageData),
    altText: "Family playing with picture frame in new home.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "There's No Place Like Home"
          }
        },
        {
          id: 2,
          subheading: {
            class: "text-white",
            text: "With Smart Start buying a house is possible."
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/personal-banking",
      title: "Personal Banking"
    },
    {
      id: 2,
      url: "/personal-banking/home-loans",
      title: "Home Loans"
    },
    {
      id: 3,
      active: true,
      title: "Smart Start"
    }
  ];
  const title = "First-Time Home Buyer Assistance with Smart Start",
    description =
      "Explore the Smart Start first-time home buyer assistance program at WaFd Bank. Enjoy as low as 1% minimum contribution, no PMI, and up to 2% loan forgiveness.";
  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/personal-banking/home-loans/smart-start-down-payment-assistance"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-smart-start-012523.jpg"
      }
    ]
  };

  const faqAccordionData = {
    id: "smart-start-faq-accordion",
    title: "Glossary",
    faqCategoryNames: ["Smart Start"]
  };

  const smartStartVideo = {
    title: "Smart Start Testimonial",
    vimeoId: "759301795",
    class: "iframe w-100 border-radius-12"
  };

  const cardsData = useCardBlogsData([
    "/blog/home-ownership/understanding-mortgages",
    "/blog/home-ownership/12-steps-buying-house",
    "/blog/home-ownership/what-do-mortgage-lenders-look-for"
  ]);

  const featuredCardsData = getFeaturedCardBlogData(cardsData, { hasText: false });

  const articleCardsData = {
    sectionClass: "bg-white",
    hasGrowEffect: true,
    cards: featuredCardsData
  };

  const [showLOModal, setShowLOModal] = useState(false);
  const handleCloseLOModal = () => setShowLOModal(false);
  const handleShowLOModal = () => {
    setShowLOModal(true);
  };

  const preApprovalData = {
    title: "Start Smart with a Pre-Approval*",
    text: "A pre-approval gives you the confidence of knowing how much house you can afford. Our pre-approval program is verified and lets sellers know you're a serious buyer who can qualify for the house you are interested in."
  };

  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container">
        <h1>Smart Start</h1>
        <div className="row">
          <div className="col-md-6 mb-3 mb-md-0">
            <VimeoVideoFrame {...smartStartVideo} />
          </div>
          <div className="col-md-6">
            <h2 className="text-success font-weight-semi-bold">
              Owning a home is more than just hype; an affordable home is the gateway to long-term and short-term
              financial success.
            </h2>
            <p>
              Long-term, you'll build equity you can use in the future and short-term, you'll be able to enjoy potential
              tax deductions and pay yourself instead of paying rent. Plus, our friendly bankers are available for any
              questions you might have, big or small.
            </p>
            <p>
              Ready for a Smart Start? Meet with a WaFd Mortgage Banker to find out how we can help you get into your
              first home.
            </p>
            <button class="btn-primary btn" id="mortgage-apply-now-link" onClick={handleShowLOModal}>
              Apply Now
            </button>
            <LoanOfficerSearch
              isModalSearch={true}
              show={showLOModal}
              loanType="Home Loan"
              handleClose={handleCloseLOModal}
            />
          </div>
        </div>
      </section>

      <section className="bg-light">
        <div className="container">
          <div className="row row-cols-1 row-cols-sm-3">
            <div className="col text-center mb-3 mb-sm-0">
              <img src="/images/icons/icon-1-percent.svg" alt="" className="mb-3" />
              <h3 className="font-weight-bold mb-0">As low as 1%</h3>
              <h4 className="mb-0">minimum contribution required*</h4>
            </div>
            <div className="col text-center mb-3 mb-sm-0">
              <img src="/images/icons/icon-no-pmi.svg" alt="" className="mb-3" />
              <h3 className="font-weight-bold mb-0">No private mortgage insurance (PMI) required*</h3>
            </div>
            <div className="col text-center">
              <img src="/images/icons/icon-smart-start-boost.svg" alt="" className="mb-3" />
              <h3 className="font-weight-bold mb-0">Smart Start Boost</h3>
              <h4 className="mb-0">available for downpayment assistance*</h4>
            </div>
          </div>
        </div>
      </section>

      <section className="container mt-4">
        <div className="row">
          <div className="col-md-6">
            <h4 id="ss-grant-funds-usage">
              Use Smart Start Boost funds toward your down payment, closing costs, or to buy down your interest rate to
              lower your monthly payments.
            </h4>
            <h4 className="text-success font-weight-semi-bold">Program/Eligibility Information:</h4>
            <ul>
              <li id="ss-program-eligibility-1">Credit score 640 and above</li>
              <li id="ss-program-eligibility-2">Household income limits apply</li>
              <li id="ss-program-eligibility-3">Must be used for purchase of primary residence</li>
              <li id="ss-program-eligibility-4">Smart Start Boost down payment assistance is a secured second lien</li>
              <li id="ss-program-eligibility-5">
                Gift funds, seller contributions and down payment assistance program funds allowed
              </li>
            </ul>
            <div className="mb-4 d-none d-md-block">
              <Link
                id="smart-start-find-loan-officer-cta-2"
                to="/personal-banking/contact-loan-officer"
                className="btn btn-primary"
              >
                Find a Local Loan Officer
              </Link>
            </div>
            <p className="text-sm text-muted mb-md-0" id="ss-terms-conditions">
              All loans subject to credit approval, additional terms and conditions may apply.
              <br />
              *Borrowers are required to contribute a minimum of 1% of the purchase price from the borrower's own funds.
              <br />
              **Minimum down payment for the Smart Start Mortgage is 3% of the purchase price. Smart Start Boost is only
              available with the Smart Start Mortgage. Smart Start Boost funds may be used to reduce the 3% down payment
              to 1% of the purchase price, reduce your closing costs, or to buy down your interest rate. Restrictions
              apply. Ask your loan officer for additional details and qualifications.
            </p>
          </div>
          <div
            id="do-you-qualify-calculator"
            className="col-md-6 px-4 bg-light border-radius-top-12 border-radius-bottom-12 mt-5 mt-md-3 mt-lg-1"
            style={{ height: "fit-content" }}
          >
            <div className="text-center" style={{ marginTop: "-55px" }}>
              <img src={calcPic} alt="" />
            </div>
            <div className="h3 text-success text-center pb-3 px-sm-3 pt-2 border-bottom">
              Do you qualify for our Smart Start Mortgage?
            </div>

            <form onSubmit={handleCalculate} className="mt-2">
              <strong>Where is the property you wish to purchase located?</strong>
              <div className="form-row justify-content-between mt-3">
                <div className="col-md-6 col-lg-5">
                  <label htmlFor="service-state" className="mr-2">
                    State
                  </label>
                  <InfoCircleTooltip
                    id={"personal-banking-tooltip"}
                    trigger={["hover", "focus"]}
                    content={
                      "To open an account with us you must reside in one of the nine western states that we operate in: Arizona, California, Idaho, Nevada, New Mexico, Oregon, Texas, Utah, or Washington."
                    }
                    icon={{
                      title: "",
                      class: "text-gray-60",
                      name: "info-circle",
                      lib: "far"
                    }}
                  />
                  <select
                    id="service-state"
                    className="form-control border-radius-6 custom-select-chevron"
                    value={serviceState}
                    onChange={handleServiceStateChange}
                  >
                    {<option value=""></option>}
                    {Object.keys(incomeData).map((option) => (
                      <option value={option}>{option}</option>
                    ))}
                  </select>
                </div>
                <div className="mt-3 mt-md-0 col-md-6 col-lg-5">
                  <label htmlFor="county" className="mr-2">
                    County
                  </label>
                  <InfoCircleTooltip
                    id={"personal-banking-tooltip"}
                    trigger={["hover", "focus"]}
                    content={"Currently we are only offering this program in select counties."}
                    icon={{
                      title: "",
                      class: "text-gray-60",
                      name: "info-circle",
                      lib: "far"
                    }}
                  />
                  <select
                    id="county"
                    className="form-control border-radius-6 custom-select-chevron"
                    value={county}
                    onChange={handleCountyChange}
                  >
                    {<option value=""></option>}
                    {incomeData[serviceState]
                      ?.sort((a, b) => a.county.localeCompare(b.county))
                      .map((option) => (
                        <option value={option.county} key={option.county}>
                          {option.county}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div className="form-row justify-content-md-end">
                <button
                  id="calculate-btn"
                  className="btn btn-primary no-min-width my-4"
                  type="submit"
                  disabled={!serviceState || !county}
                >
                  Calculate
                </button>
              </div>
            </form>

            {income ? (
              <div
                className="bg-green-60 text-white py-3 px-4 border-radius-bottom-12"
                style={{ marginLeft: "-23px", marginRight: "-23px" }}
              >
                Your household income cannot exceed{" "}
                <span className="lead" id="income">
                  {income}
                </span>{" "}
                to participate in our Smart Start Mortgage based on general county location. The exact location of the
                home may impact income limits.
                <div className="mt-3">
                  <strong>Is your income higher?</strong>{" "}
                  <Link
                    to="/personal-banking/home-loans"
                    className="text-white text-decoration-none"
                    id="loans-programs-link"
                  >
                    See additional loan programs
                  </Link>
                  <Icon name="arrow-right" class="ml-1" />
                </div>
              </div>
            ) : null}
            <div className="mb-4 d-block d-md-none">
              <Link
                id="smart-start-find-loan-officer-cta-2"
                to="/personal-banking/contact-loan-officer"
                className="btn btn-primary"
              >
                Find a local loan officer
                <Icon name="arrow-right" class="ml-2" />
              </Link>
            </div>
          </div>
        </div>
      </section>
      <PreApprovalWorkflow {...preApprovalData} />
      <section className="bg-light">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mb-3 mb-md-0">
              <StaticImage
                src="../../../images/card-understanding-smart-start-600.jpg"
                alt="Real estate agent and clients looking at paperwork in new home."
                placeholder="blurred"
                loading="eager"
                quality="100"
              />
            </div>
            <div className="col-md-6">
              <h2 className="font-weight-semi-bold">
                Understanding the Smart Start Boost - WaFd Bank's Home Loan Down Payment Assistant Program
              </h2>
              <p>
                Learn how our Smart Start Down Payment Assistance Program works for low-to-moderate income individuals
                and/or families.
              </p>
              <Link
                to="/blog/home-ownership/smart-start"
                className="btn btn-primary no-min-width"
                id="ss-read-article-btn"
              >
                Read Article
              </Link>
            </div>
          </div>
        </div>
      </section>

      <FaqAccordion {...faqAccordionData} />
      <StretchedLinkCards {...articleCardsData} />

      <BestBanksDefault />
    </SecondaryLanding>
  );
};

export default SmartStartDownPaymentAssistance;
